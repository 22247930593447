<template>
  <el-tabs
    v-model="activeName"
    tab-position="top"
    :lazy="true"
    stretch
    class="tabs-container"
  >
    <el-tab-pane label="注意事项" name="first" style="text-align: center">
      <IntroduceAttention />
    </el-tab-pane>
    <el-tab-pane
      label="上传照片"
      name="second"
      style="
        text-align: center;
        height: 100%;
        display: flex;
        flex-direction: column;
      "
    >
      <GoUpload />
    </el-tab-pane>
  </el-tabs>
</template>
<script setup>
import IntroduceAttention from "@/components/IntroduceAttention.vue";
import GoUpload from "@/components/GoUpload.vue";
import { ref } from "vue";
const activeName = ref("first");
</script>
<style>
.tabs-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.el-tabs__header {
  position: sticky;
  top: 0;
  z-index: 100;
  background: white;
}

.el-upload-list--text {
  display: none;
}
.el-tabs__content {
  height: 100%;
  overflow: hidden;
}

.el-tab-pane {
  max-height: 100%;
  overflow-y: auto;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: transparent;
}

*:hover::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: #f1f1f1;
}

*:hover::-webkit-scrollbar-thumb {
  background: #888;
}

*:hover::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
