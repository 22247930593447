import Compressor from "image-compressor.js";

export default function compressFile(
  file,
  targetSize = 2 * 1000 * 1000,
  maxAttempts = 7
) {
  return new Promise((resolve) => {
    let quality = 0.7;
    let attempts = 0;

    const compress = (inputFile) => {
      const options = {
        quality,
        success(result) {
          attempts++;
          const compressedFile = new File([result], inputFile.name, {
            type: inputFile.type,
            lastModified: Date.now(),
          });
          if (compressedFile.size <= targetSize || attempts >= maxAttempts) {
            resolve(compressedFile);
          } else {
            if (quality > 0.3) quality -= 0.1;
            compress(compressedFile); // 继续对上一次压缩的文件进行压缩
          }
        },
        error() {
          resolve(inputFile); // 在错误时也解决 Promise
        },
      };

      new Compressor(inputFile, options);
    };

    async function resizeAndCompress(inputFile) {
      return new Promise((resolve) => {
        const img = new Image();
        const imgType = inputFile.type;
        img.onload = function () {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          let width = img.width;
          let height = img.height;

          const maxDimension = 2000;
          if (width > maxDimension || height > maxDimension) {
            const aspectRatio = width / height;
            if (width > height) {
              width = maxDimension;
              height = width / aspectRatio;
            } else {
              height = maxDimension;
              width = height * aspectRatio;
            }
          } else {
            resolve(inputFile);
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(function (blob) {
            if (blob) {
              const resizedFile = new File([blob], inputFile.name, {
                type: imgType,
                lastModified: Date.now(),
              });
              resolve(resizedFile);
            } else {
              resolve(inputFile);
            }
          }, imgType);
        };

        img.onerror = function () {
          resolve(inputFile);
        };

        img.src = URL.createObjectURL(inputFile);
      });
    }

    try {
      resizeAndCompress(file)
        .then((resizedFile) => {
          if (resizedFile.size <= targetSize) {
            resolve(resizedFile);
          } else {
            compress(resizedFile);
          }
        })
        .catch(() => {
          resolve(file);
        });
    } catch (e) {
      resolve(file);
    }
  });
}
