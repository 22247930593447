<template>
  <div style="height: 100%">
    <div style="height: 75%" class="container">
      <div class="item" style="display: contents">
        <el-descriptions v-show="isLogin">
          <el-descriptions-item label="姓名:" align="center">
            <el-tag size="default">{{ name }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="状态:" align="center">
            <el-tag size="default" :type="statusType">{{ status_cn }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="" align="center">
            <el-button type="warning" size="small" @click="logout"
              >切换账号
            </el-button>
          </el-descriptions-item>
        </el-descriptions>
        <el-upload
          class="upload-demo"
          :auto-upload="false"
          :on-change="parseCode"
          v-show="!isLogin"
          accept="image/png, image/jpeg"
        >
          <el-button
            type="primary"
            style="width: 20vh"
            :disabled="!hasVerifyCode"
            :loading="collectLoding"
            >上传采集码登录
          </el-button>
        </el-upload>
      </div>
      <div class="item">
        <div
          v-show="isLogin && canUpload"
          style="height: 100%"
          class="container1"
        >
          <el-upload
            ref="uploadRef"
            class="child1"
            accept="image/png, image/jpeg"
            :action="baseUrl + '/api/uploadPic'"
            :on-success="uploadSuccess"
            :on-error="uploadError"
            :data="uploadData"
            :limit="1"
            :on-change="onChange"
            :on-exceed="handleExceed"
            :auto-upload="false"
          >
            <template #trigger>
              <el-button type="primary" style="width: 20vh"
                >{{ tip }}
              </el-button>
            </template>
            <template #file></template>
          </el-upload>
          <div class="child2" style="margin-top: 10px; z-index: 20">
            <img :src="imageUrl" class="img-thumbnail" v-show="!showUpload" />
          </div>
          <div
            style="margin-top: 10px; margin-bottom: 10px; display: block"
            class="child3"
            v-show="canStartUpload"
          >
            <el-button
              type="success"
              style="width: 10vh"
              @click="handleImage"
              v-if="!cantHandleImage"
              :loading="handleLoding"
              >更换背景
            </el-button>
            <el-select
              :disabled="cantClick"
              v-model="backgroundValue"
              @change="chooseBack"
              ref="selectRef"
              placeholder="走廊"
              style="width: 20vh"
              v-else
            >
              <el-option
                v-for="item in [
                  {
                    value: '走廊',
                    label: '走廊',
                  },
                  {
                    value: '墙画',
                    label: '墙画',
                  },
                  {
                    value: '碎花',
                    label: '碎花',
                  },
                  {
                    value: '木门',
                    label: '木门',
                  },
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
              <template #footer>
                <el-upload
                  :auto-upload="false"
                  :on-change="switchBack"
                  accept="image/png, image/jpeg"
                >
                  <el-button type="success" style="width: 10vh"
                    >上传背景
                  </el-button>
                </el-upload>
              </template>
            </el-select>

            <el-popconfirm
              confirm-button-text="上传"
              cancel-button-text="取消"
              title="证件照请更换背景后再上传。"
              @confirm="submitUpload"
            >
              <template #reference>
                <el-button
                  :loading="isLoding"
                  :disabled="cantClick"
                  style="margin-left: 12px"
                  >上 传
                </el-button>
              </template>
            </el-popconfirm>
          </div>
        </div>
      </div>
    </div>
    <el-card
      style="
        width: 90%;
        background-color: black;
        color: white;
        height: 25%;
        border-radius: 10px;
        text-align: left;
        position: absolute;
        bottom: 0;
        left: 50%; /* 将元素移动到容器的中间 */
        transform: translateX(-50%); /* 向左移动自身宽度的一半 */
        padding: 10px;
        margin-bottom: 5px;
      "
      body-style="padding: 0px;overflow-y: auto;"
    >
      <p v-for="(msg, index) in msgBox" :key="index" style="margin-bottom: 2px">
        <span style="color: yellow"
          >### <span :class="msg.type">{{ msg.msg }}</span></span
        >
      </p>
    </el-card>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { ElMessage, ElMessageBox, genFileId } from "element-plus";
import jsQR from "jsqr";
import axios from "axios";
import compressFile from "../utils/compressFile";

const showUpload = ref(true);
const imageUrl = ref("");
const uploadRef = ref(null);
const selectRef = ref(null);
const isLogin = ref(false);
const qrCodeData = ref("");
const Bearer = ref(localStorage.getItem("Bearer") || "");
const tip = ref("请选择照片");
const canStartUpload = ref(false);
const verifyCode = ref(null);
const name = ref(null);
const status_cn = ref(null);
const statusType = ref("primary");
const canUpload = ref(false);
const cantClick = ref(false);
const msgBox = ref([]);
const hasVerifyCode = ref(true);
const isLoding = ref(false);
const collectLoding = ref(false);
const cantHandleImage = ref(true);
const handleLoding = ref(false);
const imageFile = ref(null);
const backgroundValue = ref("未换底");
let foregroundFile = null;
// const baseUrl =
//   "https://service-42fa5zzc-1300811203.gz.tencentapigw.com.cn/release";
const baseUrl = "https://tucai.gxx.cool:8082";
// const baseUrl = "http://localhost:8082";
const hanleImageBaseUrl = "https://tucai.gxx.cool:8083";
const uploadData = ref({
  Bearer: Bearer.value,
  verifyCode: verifyCode.value,
  handledImage: backgroundValue.value,
});

onMounted(() => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  ``;
  verifyCode.value = params.verifyCode;
  if (!params.verifyCode || params.verifyCode.length < 6) {
    hasVerifyCode.value = false;
    ElMessage({
      showClose: true,
      message: "缺少校验码！",
      type: "warning",
    });
    return;
  }
  ElMessageBox.alert(
    "<p style='color: red;font-weight: bold'>1. 证件照上传前最好使用网页提供的功能更换背景！</p><p style='color: #AF0833'>2. 审核老师可以看到图采系统处理前的照片，所以纯色背景的证件照很容易被识别！！</p><p style='color: #650000'>3. 更换背景不会影响最终效果，但可以大大提高上传成功率，同时也更不容易被审核老师认出是证件照，从而提高通过率！！！</p>",
    "注意事项",
    {
      closeOnClickModal: false,
      closeOnPressEscape: false,
      closeOnHashChange: false,
      showCancelButton: false,
      dangerouslyUseHTMLString: true,
      showClose: false,
      confirmButtonText: "知道了",
      callback: () => {
        uploadData.value.verifyCode = params.verifyCode;
      },
    }
  );
});
const parseCode = async (file) => {
  if (
    file.raw &&
    (file.raw.type === "image/jpeg" || file.raw.type === "image/png")
  ) {
    if (file.size > 3 * 1024 * 1024) {
      ElMessage({
        showClose: true,
        message: "采集码大小不能超过3M",
        type: "warning",
      });
      return;
    }
    const reader = new FileReader();

    reader.onload = () => {
      const imageData = new Image();
      imageData.src = reader.result;

      imageData.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = imageData.width;
        canvas.height = imageData.height;
        ctx.drawImage(imageData, 0, 0, imageData.width, imageData.height);

        const imageDataContext = ctx.getImageData(
          0,
          0,
          imageData.width,
          imageData.height
        );
        let code = jsQR(
          imageDataContext.data,
          imageDataContext.width,
          imageDataContext.height
        );
        if (code && code.data.substr(0, 6) == "eyJzam") {
          qrCodeData.value = code.data;
          login(code.data);
        } else {
          ElMessage({
            showClose: true,
            message: "未识别到图采二维码",
            type: "warning",
          });
        }
      };
    };
    reader.readAsDataURL(file.raw);
  } else {
    ElMessage({
      showClose: true,
      message: "请上传图片",
      type: "warning",
    });
  }
};

const logout = () => {
  isLogin.value = false;
};

function login(code) {
  collectLoding.value = true;
  if (code) {
    axios
      .post(baseUrl + "/api/getLoginVal", {
        code,
        verifyCode: verifyCode.value,
      })
      .then((res) => {
        collectLoding.value = false;
        if (res.data.code === 200) {
          initUserInfo(res.data);
          ElMessage({
            showClose: true,
            message: "登录成功",
            type: "success",
          });
          msgBox.value = [];
          msgBox.value.unshift({
            msg: "登录成功",
            type: "green",
          });
          Bearer.value = res.data.Bearer;
          uploadData.value.Bearer = res.data.Bearer;
          isLogin.value = true;
          localStorage.setItem("Bearer", res.data.Bearer);
        } else {
          ElMessage({
            showClose: true,
            message: "登录失败",
            type: "error",
          });
          msgBox.value.unshift({
            msg: res.data.msg,
            type: "red",
          });
        }
      })
      .catch(() => {
        collectLoding.value = false;
        ElMessage({
          showClose: true,
          message: "登录失败",
          type: "error",
        });
        msgBox.value.unshift({
          msg: "网络波动，请重试登录",
          type: "red",
        });
      });
  }
}

const initUserInfo = (data) => {
  uploadRef.value.clearFiles();
  imageUrl.value = "";
  tip.value = "请选择照片";
  showUpload.value = true;
  canStartUpload.value = false;
  name.value = data.msg;
  status_cn.value = data.upload_status_cn;
  if (data.status) {
    canUpload.value = true;
    statusType.value = "success";
  } else {
    canUpload.value = false;
    statusType.value = "danger";
    if (
      data.upload_status_cn === "待上传" ||
      data.upload_status_cn === "已重置"
    ) {
      msgBox.value.unshift({
        msg: "不在采集时间内",
        type: "red",
      });
    }
  }
};

const handleExceed = (files) => {
  uploadRef.value.clearFiles();
  const file = files[0];
  file.uid = genFileId();
  uploadRef.value.handleStart(file);
};

const onChange = async (file) => {
  if (file.response || file.raw.response) {
    return;
  }
  if (file.raw.type !== "image/jpeg" && file.raw.type !== "image/png") {
    ElMessage({
      showClose: true,
      message: "请上传图片",
      type: "warning",
    });
    return;
  }
  cantHandleImage.value = false;
  uploadData.value.handledImage = "未换底";
  isLoding.value = true;
  cantClick.value = true;
  tip.value = "更换照片";
  showUpload.value = false;
  compressFile(file.raw).then((compressedFile) => {
    if (!compressedFile.size || compressedFile.size === 0) {
      compressedFile = file.raw;
    }
    imageUrl.value = URL.createObjectURL(compressedFile);
    compressedFile.uid = genFileId();
    compressedFile.response = "success";
    uploadRef.value.clearFiles();
    uploadRef.value.handleStart(compressedFile);
    imageFile.value = compressedFile;
    canStartUpload.value = true;
    cantClick.value = false;
    isLoding.value = false;
  });
};

const submitUpload = () => {
  cantClick.value = true;
  isLoding.value = true;
  uploadRef.value.submit();
};

function mergeImages(foregroundFile, backgroundFile) {
  return new Promise((resolve, reject) => {
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");

    var loadForeground = new Image();
    var loadBackground = new Image();

    // 创建从文件对象到URL的链接
    var foregroundURL = URL.createObjectURL(foregroundFile);
    var backgroundURL = URL.createObjectURL(backgroundFile);

    loadForeground.src = foregroundURL;
    loadForeground.onload = function () {
      // 加载背景图
      loadBackground.src = backgroundURL;
      loadBackground.onload = function () {
        // 调整Canvas大小以适应前景图
        canvas.width = loadForeground.width;
        canvas.height = loadForeground.height;

        // 调整背景图以匹配前景图大小并绘制
        ctx.drawImage(loadBackground, 0, 0, canvas.width, canvas.height);
        // 绘制前景图
        ctx.drawImage(loadForeground, 0, 0, canvas.width, canvas.height);

        // 将canvas内容转换为Blob对象
        canvas.toBlob((blob) => {
          // 清理创建的URL
          URL.revokeObjectURL(foregroundURL);
          URL.revokeObjectURL(backgroundURL);
          resolve(blob);
        }, "image/jpeg");
      };
      loadBackground.onerror = () => {
        URL.revokeObjectURL(backgroundURL);
        reject("Background image could not be loaded.");
      };
    };
    loadForeground.onerror = () => {
      URL.revokeObjectURL(foregroundURL);
      reject("Foreground image could not be loaded.");
    };
  });
}

const fetchImageFile = async (backgroundImage) => {
  const response = await fetch(backgroundImage);
  const blob = await response.blob();
  const file = new File([blob], "background.jpg", { type: blob.type });
  return file;
};

const chooseBack = (value) => {
  //从assets中获取对应的背景文件
  let backgroundFile = require(`../assets/${value}.jpg`);
  fetchImageFile(backgroundFile).then((file) => {
    uploadData.value.handledImage = value;
    changeBack(file);
  });
};

const switchBack = async (file) => {
  if (file.raw.type !== "image/jpeg" && file.raw.type !== "image/png") {
    ElMessage({
      showClose: true,
      message: "请上传图片",
      type: "warning",
    });
    return;
  }
  if (file.raw.size > 1024 * 1024) {
    const compressedFile = await compressFile(file.raw);
    compressedFile.uid = genFileId();
    changeBack(compressedFile);
  } else {
    changeBack(file.raw);
  }
  backgroundValue.value = "本地上传";
  uploadData.value.handledImage = backgroundValue.value;
  selectRef.value.blur();
};
const changeBack = (file) => {
  mergeImages(foregroundFile, file)
    .then((blob) => {
      const newFile = new File([blob], "newImage.jpg", {
        type: "image/jpeg",
      });
      newFile.response = "success";
      newFile.uid = genFileId();
      uploadRef.value.clearFiles();
      uploadRef.value.handleStart(newFile);
      imageFile.value = newFile;
      imageUrl.value = URL.createObjectURL(blob);
      msgBox.value.unshift({
        msg: "背景更换成功",
        type: "green",
      });
    })
    .catch((e) => {
      msgBox.value.unshift({
        msg: e,
        type: "red",
      });
    });
};

const handleImage = () => {
  cantClick.value = true;
  handleLoding.value = true;
  let formData = new FormData();
  formData.append("file", imageFile.value);
  axios
    .post(hanleImageBaseUrl + "/api/handleImage", formData, {
      responseType: "blob",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      cantHandleImage.value = true;
      foregroundFile = res.data;
      imageUrl.value = URL.createObjectURL(res.data);
      const file = new File([res.data], "newImage.jpg", {
        type: "image/jpeg",
      });
      file.response = "success";
      file.uid = genFileId();
      uploadRef.value.clearFiles();
      uploadRef.value.handleStart(file);
      msgBox.value.unshift({
        msg: "图片处理完成",
        type: "green",
      });
      handleLoding.value = false;
      cantClick.value = false;
      chooseBack("走廊");
      backgroundValue.value = "走廊";
      uploadData.value.handledImage = "走廊";
    })
    .catch((e) => {
      cantClick.value = false;
      e.response.data
        .text()
        .then((text) => {
          handleLoding.value = false;
          msgBox.value.unshift({
            msg: text,
            type: "red",
          });
        })
        .catch((e) => {
          console.log(e);
        });
    });
};

const uploadSuccess = (response) => {
  isLoding.value = false;
  if (response.code === 200) {
    imageUrl.value = response.url;
    ElMessage({
      showClose: true,
      message: "上传成功",
      type: "success",
    });
    msgBox.value.unshift({
      msg: response.msg,
      type: "green",
    });
  } else {
    let msg = "";
    if (typeof response.msg == "object") {
      for (let i = 0; i < response.msg.length; i++) {
        msg += response.msg[i].message + "：" + response.msg[i].tips + "\n";
      }
      msgBox.value.unshift({
        msg: msg,
        type: "red",
      });
    } else {
      msgBox.value.unshift({
        msg: response.msg,
        type: "red",
      });
    }
  }
};

const uploadError = () => {
  isLoding.value = false;
  ElMessage({
    showClose: true,
    message: "上传失败",
    type: "error",
  });
  msgBox.value.unshift({
    msg: "上传失败，请重试",
    type: "red",
  });
};
</script>
<style scoped>
.red {
  color: red;
}

.green {
  color: lightgreen;
}

.container {
  display: flex;
  flex-direction: column;
  height: 75%;
}

.item:first-child {
  align-self: flex-start; /* 第一个元素自适应内容高度 */
}

.item:nth-child(2) {
  flex-grow: 1; /* 第二个元素填充剩余空间 */
  overflow: hidden; /* 内容溢出时隐藏 */
}

.container1 {
  display: flex; /* 设置为Flex容器 */
  flex-direction: column; /* 主轴方向为垂直 */
  align-items: center; /* 交叉轴居中对齐 */
}

.child2 {
  flex-grow: 1;
  position: relative; /* 设置为相对定位 */
  overflow: hidden; /* 隐藏溢出的内容 */
  width: 100%;
}

.child2 img {
  position: absolute; /* 绝对定位，脱离文档流 */
  top: 50%; /* 垂直居中 */
  left: 50%; /* 水平居中 */
  transform: translate(-50%, -50%); /* 校正图片的定位 */
  max-height: 100%; /* 限制最大高度，避免撑开容器 */
  max-width: 100%; /* 限制最大宽度 */
  height: auto; /* 高度自适应 */
  width: auto;
}
</style>
