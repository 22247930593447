<template>
  <div class="guide">
    <h5><span class="icon">🚩</span>必过准则:</h5>
    <p style="text-indent: 2em">
      <span style="color: #b10000; font-weight: bold">
        审核老师可以看到上传原图</span
      >，所以背景很关键！！对于不准使用证件照的院校，<span
        style="color: #b10000"
        >建议大家请舍友同学帮忙拍一下，然后进行美颜</span
      >，这种通过率几乎百分百！
    </p>
    <ul>
      <li>眼镜如果反光，最好摘掉眼镜</li>
      <li>
        由于上传成功后整体亮度会提升，<span style="color: #b10000"
          >建议调整脸部高光，降低整体亮度</span
        >
      </li>
      <li>脸上的光线要均匀，身体不能歪，不能戴项链，不能露出牙齿</li>

      <li>
        <span style="color: #b10000; font-weight: bold"
          >证件照上传前，先使用网页提供的功能更换背景，更容易成功！</span
        >
      </li>
    </ul>
    <p style="text-indent: 2em">
      尽可能使用稍微美颜过的生活照，上传证件照要<span style="color: #b10000"
        >做好被打回的准备</span
      >。可以尝试一次，如果被打回的话最好换生活照再传！轻易不要挑战老师的底线！
    </p>
    <p style="text-indent: 2em">
      <span class="icon">🉑</span>官网的标准如下（形象版）
    </p>
    <img src="../assets/model.jpg" alt="官网标准" style="width: 100%" />
  </div>
</template>

<style scoped>
.guide {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.6;
  color: #333;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.guide h2,
.guide h3 {
  color: #d00;
  margin-bottom: 10px;
}

.guide p {
  margin-bottom: 15px;
}

.guide .icon {
  font-size: 1.2em;
  vertical-align: middle;
}

.guide ul,
.guide ol {
  margin-left: 20px;
  margin-bottom: 15px;
}

.guide li {
  margin-bottom: 10px;
}

.guide ol li {
  list-style-type: decimal;
}

.guide a {
  color: #007bff;
  text-decoration: none;
}

.guide a:hover {
  text-decoration: underline;
}
</style>
<script setup></script>
