<template>
  <el-row>
    <el-col :xs="0" :sm="6" :md="7" :lg="8" :xl="9">
      <div class="grid-content ep-bg-purple" />
    </el-col>
    <el-col :xs="24" :sm="12" :md="10" :lg="8" :xl="6">
      <el-container class="app-container">
        <el-header>
          <HeadTip style="text-align: center" />
        </el-header>
        <el-main style="padding: 0">
          <MyTabs />
        </el-main>
        <!--        <el-footer> <AddUp /></el-footer>-->
      </el-container>
    </el-col>
    <el-col :xs="0" :sm="6" :md="7" :lg="8" :xl="9"></el-col>
  </el-row>
</template>

<script setup>
import MyTabs from "@/components/MyTabs.vue";
import HeadTip from "@/components/HeadTip.vue";
import { onMounted, onUnmounted } from "vue";

onMounted(() => {
  const setDynamicHeight = () => {
    const vh = window.innerHeight;
    document.documentElement.style.setProperty("--dynamic-height", `${vh}px`);
  };

  setDynamicHeight();

  // 监听窗口大小变化，以便在窗口大小变化时更新高度
  window.addEventListener("resize", setDynamicHeight);

  // 在组件卸载时移除事件监听器
  onUnmounted(() => {
    window.removeEventListener("resize", setDynamicHeight);
  });
});
</script>

<style scoped>
.el-main {
  flex: 1; /* 使el-main伸展以填充剩余空间 */
}

.el-header {
  --el-header-height: auto;
  --el-header-padding: 0;
}
</style>
