<script setup></script>

<template>
  <div class="fancy-text">图采自助站</div>
</template>

<style scoped>
.fancy-text {
  font-family: "Lucida Calligraphy", "Arial", sans-serif; /* 更具艺术感的字体 */
  color: transparent; /* 使文字颜色透明以显示背景的渐变色 */
  -webkit-background-clip: text; /* 使背景仅在文字处显示 */
  background-clip: text; /* 同上，适用于非webkit浏览器 */

  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6); /* 增加文字阴影效果的尺寸 */
  font-size: 50px; /* 增大字体大小 */
  font-weight: bold; /* 字体加粗 */
  letter-spacing: 15px; /* 增加字母间距 */
  text-align: center; /* 文字居中显示 */
  margin-bottom: -10px;
  background-image: linear-gradient(
    45deg,
    #ff305b,
    /* 鲜艳的粉红 */ #ff9623,
    /* 鲜艳的橙色 */ #18cfab /* 鲜艳的蓝绿色 */
  ); /* 背景渐变色 */
  white-space: nowrap;
}
</style>
