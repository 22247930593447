import { createApp } from "vue";
import App from "./App.vue";
import "jquery/dist/jquery.min";
import "bootstrap/dist/css/bootstrap.css";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import * as devtoolsDetector from "devtools-detector";

const app = createApp(App);
if (process.env.NODE_ENV !== "development") {
  devtoolsDetector.addListener((isOpen) => {
    //关闭当前网页
    if (isOpen) {
      window.location.href = "about:blank";
    }
  });
  devtoolsDetector.launch();

  document.addEventListener("keydown", (e) => {
    if (
      e.key === "F12" ||
      ((e.ctrlKey || e.metaKey) &&
        e.shiftKey &&
        (e.key === "I" || e.key === "J"))
    ) {
      e.preventDefault();
    }
  });
}

app.use(ElementPlus);
app.mount("#app");
